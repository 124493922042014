import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-edb10bae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lSetting" }
const _hoisted_2 = { class: "lSetting_wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_header_mobile = _resolveComponent("as-header-mobile")!
  const _component_as_menu = _resolveComponent("as-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_as_header_mobile, {
        title: _ctx.$t('menu'),
        back: "",
        nav: false
      }, null, 8, ["title"]),
      _createVNode(_component_as_menu, {
        menu: _ctx.menu,
        class: "lSetting_asMenu -setting"
      }, null, 8, ["menu"])
    ])
  ]))
}