
import { defineComponent, computed, onMounted } from 'vue'

import AsMenu from '@web/components/AsMenu.vue'
import { useStore } from '@web/store'
import AsHeaderMobile from '@web/components/headers/AsHeaderMobile.vue'

export default defineComponent({
  components: {
    AsHeaderMobile,
    AsMenu
  },
  setup () {
    const store = useStore()

    const menu = computed(() => store.getters['menu/mobile'])

    onMounted(() => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)

      window.addEventListener('resize', () => {
        const vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
      })
    })

    return {
      menu
    }
  }
})
